import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/signin',
		component: () => import('@/views/SignIn'),
	},
	{
		path: '',
		component: () => import('@/layouts/BaseLayout'),
		meta: { requiresAuth: true },
		props: {
			paths: [
				{ text: '商戶', value: '/merchants' },
				{ text: '分店', value: '/shops' },
				{ text: '裝置', value: '/devices' },
				{ text: '打印機', value: '/printers' },
			],
		},
		children: [
			{ path: '/merchants', component: () => import('@/views/MerchantList') },
			{ path: '/merchants/:id', component: () => import('@/views/MerchantDetail') },
			{ path: '/shops', component: () => import('@/views/ShopList') },
			{ path: '/shops/:id', component: () => import('@/views/ShopDetail') },
			{ path: '/devices', component: () => import('@/views/DeviceList') },
			{ path: '/devices/:id', component: () => import('@/views/DeviceDetail') },
			{ path: '/printers', component: () => import('@/views/PrinterList') },
			{ path: '/printers/:id', component: () => import('@/views/PrinterDetail') },
		],
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	if (!to.matched.some(record => record.meta.requiresAuth)) return next()
	if (Store.getters.authState) return next()
	next({ path: '/signin' })
})

export default router
